*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  background: rgba(236, 238, 240, 0.7);
}

a {
  color: inherit;
  text-decoration: none;
}

button,
input {
  border: 0;
  padding: 0;
  outline: none;
  font-family: 'Roboto', sans-serif;
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

mark {
  opacity: 0.5;
  background: #ff0;
  box-shadow: 0px 2px 10px #ff0;
  color: transparent;
  white-space: pre;
}
li {
  list-style-position: inside;
}
